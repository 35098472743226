
import moment from "moment";
import ApiService from "@/core/services/ApiService";
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { useI18n } from "vue-i18n";
import { date } from "yup/lib/locale";
import { useStore } from "vuex";
import "emoji-picker-element";

export default defineComponent({
  name: "Actualités",
  components: {},
  setup() {
    const store = useStore();
    const commentsRow = ref<any[]>([]);

    const { t } = useI18n();
    const actualitiesData: any = ref([]);
    const token = window.localStorage.getItem("id_token");
    const decodeJwt = (token: string | null) => {
      if (!token) return "";
      const [header, payload, signature] = token.split(".");

      const decodedPayload = JSON.parse(atob(payload));

      return decodedPayload.id;
    };
    const TeacherId = ref(decodeJwt(token));

    const visibleLikes = ref<boolean>(false);
    const loadingLikes = ref<boolean>(true);
    const loadingActuality = ref<boolean>(true);

    const likes = ref<any[]>([]);

    const apiUrl = store.getters.serverConfigUrl.base_url + "/";

    const fetchData = () => {
      ApiService.get("/actuality/teachers")
        .then(({ data }) => {
          commentsRow.value = [];
          let filterdData: any = [];
          data.forEach((element: any) => {
            commentsRow.value.push("");
            let photos = element.photos.map((photo) => {
              return apiUrl + photo;
            });

            element.photos = photos;
            element.showComments = true;
            element.processing = false;
            filterdData.push(element);
          });
          actualitiesData.value = filterdData;
        })
        .catch((e) => console.log(e))
        .finally(() => (loadingActuality.value = false));
    };
    fetchData();
    const submitComment = (
      comment: string,
      actualityId: string,
      actualityIndex: number
    ) => {
      if (comment == "" || comment == " " || !comment) return;
      const request: any = {
        desc: comment,
        actuality: actualityId,
        type: "Teacher",
        teacher: TeacherId.value,
      };
      const user = store.state.AuthModule.user;
      actualitiesData.value[actualityIndex].comments.push({
        ...request,
        fullName: user.employee.firstName + " " + user.employee.lastName,
        photo: user.employee.photo,
      });
      commentsRow.value = [];

      ApiService.post("/actuality/comment", { ...request })
        .then(({ data }) => {
          fetchData();
        })
        .catch((e) => console.log(e));
    };

    const submitLike = (actualityId: string, actualityIndex: number) => {
      actualitiesData.value[actualityIndex].processing = true;

      const request: any = {
        actuality: actualityId,
        type: "Teacher",
        teacher: TeacherId.value,
      };
      actualitiesData.value[actualityIndex].liked = true;
      actualitiesData.value[actualityIndex].total_likes++;
      ApiService.post("/actuality/like", { ...request })
        .then(({ data }) => {
          fetchData();
        })
        .catch((e) => {
          console.log(e);
          actualitiesData.value[actualityIndex].liked = false;
          actualitiesData.value[actualityIndex].total_likes--;
        })
        .finally(
          () => (actualitiesData.value[actualityIndex].processing = false)
        );
    };

    const submitUnlike = (actualityId: string, actualityIndex: number) => {
      actualitiesData.value[actualityIndex].liked = false;
      actualitiesData.value[actualityIndex].total_likes--;
      actualitiesData.value[actualityIndex].processing = true;

      ApiService.delete("/actuality/like/remove/" + actualityId)
        .then(({ data }) => {
          actualitiesData.value[actualityIndex].liked = false;
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(
          () => (actualitiesData.value[actualityIndex].processing = false)
        );
    };

    const deleteComment = (idComment: string) => {
      if (!idComment) return;
      ApiService.delete("/actuality/comment/" + idComment)
        .then(({ data }) => {
          fetchData();
        })
        .catch((e) => console.log(e));
    };

    const toggleComment = (actualityIndex: number) => {
      actualitiesData.value[actualityIndex].showComments =
        !actualitiesData.value[actualityIndex].showComments;
    };

    const showWhoLiked = (actuality: any) => {
      loadingLikes.value = true;
      visibleLikes.value = true;
      ApiService.get("/actuality/likes/" + actuality._id)
        .then(({ data }) => {
          likes.value = data.likes;
          loadingLikes.value = false;
        })
        .catch((e) => console.log(e));
    };

    const toggleEmojis = (actualityIndex: number) => {
      actualitiesData.value[actualityIndex].emojis =
        !actualitiesData.value[actualityIndex].emojis;
    };

    const setEmoji = (emoji, index: number) => {
      commentsRow.value[index] =
        commentsRow.value[index] + emoji.detail.unicode;
      toggleEmojis(index);
    };
    const onSelectEmoji = (emoji, index: number) => {
      commentsRow.value[index] =
        commentsRow.value[index] + emoji.detail.unicode;
      toggleEmojis(index);
    };

    onMounted(() => {
      setCurrentPageTitle(t("dashboard.actualites"));
    });
    return {
      date,
      apiUrl,
      moment,
      actualitiesData,
      t,
      store,
      commentsRow,
      submitComment,
      submitLike,
      submitUnlike,
      TeacherId,
      deleteComment,
      toggleComment,
      visibleLikes,
      likes,
      showWhoLiked,
      onSelectEmoji,
      toggleEmojis,
      setEmoji,
      loadingLikes,
      loadingActuality,
    };
  },
});
